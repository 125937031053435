import React, { Component }  from 'react';
import { Event } from './Events';
import moment, { Moment } from 'moment'

interface State {
    currentTime: Moment;
}

interface Props {
    event: Event;
}


export default class EventBlock extends Component<Props,State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            currentTime: moment()
        }
    }

    private timeoutId?: any;

    updateCurrentTime() {
        this.setState({
          currentTime: moment(),
        });
        this.timeoutId = setTimeout(this.updateCurrentTime.bind(this), 1000);
      }
    
      componentDidMount() {
        this.updateCurrentTime();
        document.addEventListener('visibilitychange', () => {
          if(document.hidden) {
            clearTimeout(this.timeoutId);
          } else {
            this.updateCurrentTime();
          }
        }, false);
      }
    
      componentWillUnmount() {
        clearTimeout(this.timeoutId);
      }
      
    render() {
        
        let age = moment.duration(this.state.currentTime.diff(
            this.props.event.start))
        if (age.asMilliseconds() < 0)
          age = moment.duration(0, "s")
        const seconds = age.seconds()
        let ageclass = "fresh"
        if (age.asMinutes() > 4)
          ageclass = "urgent";
        else if (age.asMinutes() > 2)
          ageclass = "warning";

        if (age.asMinutes() > 59)
          return <div/>

        return <div className={'eventblock ' + ageclass}>
            <p>{this.props.event.location}</p>
            <p>{Math.floor(age.asMinutes())}:
            {(seconds < 10) ? "0" + seconds : seconds}</p>
        </div>
    }
    

}

