import React from 'react';
import './App.css';
import Dashboard from './Dashboard'

interface Props {
    site: string;
}

function App(props: Props) {

    if (props.site == "HWK") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'HWK',
                        name: 'Hawkinge'
                    }
                ]

            }
        />
    }
    else if (props.site == "PAU") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'PAU',
                        name: 'Hawkinge PAU'
                    }
                ]
            }
        />
    }
    else if (props.site == "COR") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'COR',
                        name: 'Cornford'
                    }
                ]
            }
        />
    }
    else if (props.site == "HAW") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'HAW',
                        name: 'Hawkhurst'
                    }
                ]
            }
        />
    }
    else if (props.site == "HAZ") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'HAZ',
                        name: 'Hazeldene'
                    }
                ]
            }
        />
    }
    else if (props.site == "ROD") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'ROD',
                        name: 'Rodwell'
                    }
                ]
            }
        />
    }
    else if (props.site == "WOO") {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'WOO',
                        name: 'Woodchurch'
                    }
                ]
            }
        />
    }
    else {
        return <Dashboard
            sites={
                [
                    {
                        tag: 'PAU',
                        name: 'Hawkinge PAU'
                    },
                    {
                        tag: 'HWK',
                        name: 'Hawkinge'
                    },
                    {
                        tag: 'COR',
                        name: 'Cornford'
                    },
                    {
                        tag: 'HAW',
                        name: 'Hawkhurst'
                    },
                    {
                        tag: 'HAZ',
                        name: 'Hazeldene'
                    },
                    {
                        tag: 'ROD',
                        name: 'Rodwell'
                    },
                    {
                        tag: 'WOO',
                        name: 'Woodchurch'
                    }
                ]
            }
        />
    }
}

    export default App;
