import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
        <Route exact path="/">
            <div className="container-fluid bg-blue pt-5 px-5" />
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="All" />
            </div>
        </Route>
        <Route exact path="/HWK">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Hawkinge House</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="HWK" />
            </div>
        </Route>
        <Route exact path="/PAU">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Hawkinge PAU</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="PAU" />
            </div>
        </Route>
        <Route exact path="/COR">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Cornford</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="COR" />
            </div>
        </Route>
        <Route exact path="/HAW">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Hawkhurst</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="HAW" />
            </div>
        </Route>
        <Route exact path="/HAZ">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Hazeldene</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="HAZ" />
            </div>
        </Route>
        <Route exact path="/ROD">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Rodwell</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="ROD" />
            </div>
        </Route>
        <Route exact path="/WOO">
            <div className="container-fluid bg-blue pt-5 px-5">
                <h1 className="m-0 t-white">Woodchurch</h1>
            </div>
            <div id="mast"><img src="/mast.jpg" /></div>

            <div className="container-fluid pt-5 px-5">
                <App site="WOO" />
            </div>
        </Route>
    </Router >,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
