import React, { FunctionComponent }  from 'react';
import { Event } from './Events';
import EventBlock from './EventBlock'

interface Props {
    sitename: string;
    events: Event[];
}


export const Column: FunctionComponent<Props> = ({sitename, events}) =>
<div className='column'>
    {events
    .sort((a, b) => - a.start.diff(b.start))
    .map((ev, idx) => <EventBlock key={ev.id} event={ev}/>)}
</div>
