import React, { Component, ReactNode } from 'react';
import {Column} from './Column'
import moment, { Moment } from 'moment'
import * as signalR from "@microsoft/signalr";


interface Site {
    tag: string,
    name: string
}

interface Props {
    sites: Site[]
}

interface HubEvent {
    id: number,
    start: string,
    location: string,
    site: string,
    type: string
}


interface State {
    connectionState?: string,
    currentTime: Moment;
    events: HubEvent[];
}

export default class Dashboard extends Component<Props,State> {

    private hubConnection: signalR.HubConnection;

    constructor(props: Props) {
        super(props);
        this.state = {
            connectionState: "Connecting...",
            currentTime: moment(),
            events: []
        }
        // Builds the SignalR connection, mapping it to /chat
        this.hubConnection = new signalR.HubConnectionBuilder()
            // .withUrl("http://localhost:7071/api")
            .withUrl("https://gcarmcallprocessor.azurewebsites.net/api")
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.hubConnection.on("dashboard", (sev: HubEvent[]) => {

            console.log("Received update");
            this.setState({
                connectionState: undefined,
                events: sev
            })
        });

        this.hubConnection.onreconnecting(a => {
            console.log("Reconnecting");
            this.setState({
                connectionState: "Reconnecting..."
            })
        })

        this.hubConnection.onreconnected(a => {
            console.log("Reconnected");
            this.doFetch();
        })

        this.hubConnection.onclose(a => {
            console.log("Closed");
            this.setState({
                connectionState: "Closed."
            });
            setTimeout(a => this.doStart(), 6000);
        })

    }

    componentDidMount() {
        this.doStart();
    }

    private doStart() {

        // Starts the SignalR connection
        this.hubConnection.start().then(a => {            
            console.log("Starting");
            this.doFetch()
        }).catch(e => {
            this.setState({
                connectionState: "Error Connecting"
            })
        });  
    }

    private doFetch() {

            // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
            window.fetch("https://gcarmcallprocessor.azurewebsites.net/api/Refresh", {
                method: "POST",
                headers: {
                    "Accept": "text/plain"
                }
            });

    }

    render() {

        if (this.state.connectionState) {
            return <div className="connectionstate">{this.state.connectionState}</div>
        }

      
        let bysite = this.state.events.reduce((objectsByKeyValue: Map<string,HubEvent[]>, obj) => {
            const value = obj.site;
            objectsByKeyValue.set(value, (objectsByKeyValue.get(value) || []).concat(obj));
            return objectsByKeyValue;
          }, new Map<string,HubEvent[]>());

        return (<div className="App">
            {this.props.sites.map(site => 
                <Column sitename={site.name}
                    key={site.tag}
                    events={bysite.get(site.tag)?.map(ev => 
                        ({
                            id: ev.id,
                            location: ev.location,
                            start: moment(ev.start),
                            type: ev.type
                        })) || [] }
                        />
            )}
    </div>)
    }
}



 